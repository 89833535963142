<template>
    <div class="inteBox">
        <intNavheader></intNavheader>
        <div class="titleTopLine">
            
        </div>
        <el-button @click="goPrint">打印</el-button>
        <div ref="print" class="PrintBox"> 
            <div class="PrintBoxTitle">门诊处方笺</div>
            <div class="PrintBoxTitle1"> 
                <div>就诊日期：{{ patientDetail.mzPrescriptionOut.addTime | timefilters }}</div>
                <div>病历号:</div>
            </div>
            <div class="PrintBoxTitle2"> 
                <div>姓名：{{ patientDetailAll.name }}</div>
                <div>性别:{{ patientDetailAll.sex ? '男' : '女'  }}</div>
                <div>年龄:{{ patientDetailAll.age }}</div>
                <div>科室:无</div>
            </div>
            <div class="PrintBoxTitleLine"></div>
            <div>
                <div>诊断: {{ patientDetailAll.patientDiagnose[0].diseaseDiagnose }}</div>
            </div>
            <div class="PrintBoxTitleLineD"></div>
            <div style="font-size:32px; margin:10px 0">R</div>
            <!-- 中哟方剂 -->
            <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0"> 
                <div v-for="(item,index) in patientDetail.mzPrescriptionOut.mzPrescriptionYP" :key="index">
                    <div class="flex-l" style="margin-left:15px;margin-top:5px" >
                        <div style="color:#999999">{{ item.prescriptionName }}</div>
                    </div>
                    <div style="margin-left:15px;margin-top:5px" v-for="(item1,index1) in item.mzPrescriptionDetail" :key="index1">
                        <div></div>
                        <div><span>{{item1.drugOrgName}},{{item1.weight}}</span></div>
                      
                    </div>
                    <div style="display:flex;align-items:center">
                        <div>剂数：{{ item.dosage }}</div>
                        <div style="margin-left:10px">每日剂量: {{ item.dosageNumber }}</div>
                    </div>
                    <div style="display:flex;align-items:center">
                        <div>用药频率{{ item.frequency }}</div>
                        <div style="margin-left:10px">用法: {{ item.usage }}</div>
                    </div>
                    <div>
                        <div>服用要求：{{ item.request }}</div>
                    </div>
                </div>
            </div>
            <!-- 成药方剂 -->
            <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0"> 
                <div v-for="(item,index) in patientDetail.mzPrescriptionOut.mzPrescriptionCY" :key="index">
                    <div class="flex-l" style="margin-left:15px;margin-top:5px" >
                        <div style="color:#999999">{{ item.prescriptionName }}</div>
                    </div>
                    <div style="margin-left:15px;margin-top:5px" v-for="(item1,index1) in item.mzPrescriptionDetail" :key="index1">
                        <el-table :data="item.mzPrescriptionDetail">
                            <el-table-column prop="drugOrgName" align="center" label="药品名称">
                                <template slot-scope="scope">
                                    {{ scope.row.drugOrgName }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="groupNumber" align="center" label="组号">
                                <template slot-scope="scope">
                                    {{ scope.row.groupNumber }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="usage" align="center" label="用法">
                                <template slot-scope="scope">
                                    {{ scope.row.usage }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="frequency" align="center" label="用药频率">
                                <template slot-scope="scope">
                                    {{ scope.row.frequency }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="dosage" align="center" label="单次剂量">
                                <template slot-scope="scope">
                                    {{ scope.row.dosage + scope.row.dosageUnit }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="defaultDosage" align="center" label="开药量">
                                <template slot-scope="scope">
                                    {{ scope.row.defaultDosage +  scope.row.defaultDosageUnit }}
                                </template>
                            </el-table-column>
                             <el-table-column prop="tradingPrice" align="center" label="单价">
                                <template slot-scope="scope">
                                    {{ scope.row.tradingPrice}}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
                 <!-- 输液方剂 -->
            <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0"> 
                <div v-for="(item,index) in patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup" :key="index">
                    <div class="flex-l" style="margin-left:15px;margin-top:5px" >
                        <div style="color:#999999">{{ item.prescriptionName }}</div>
                    </div>
                    <div  v-for="(item1,index1) in item.mzPrescriptionGroups" :key="index1">
                        <div style="margin-left:15px;margin-top:5px">
                            <div></div>
                            <div><span>组{{ index1 + 1 }}  用法：{{item1.usage}};用药频率：{{item1.frequency}};滴速 {{item1.drippingSpeed}}{{item1.drippingSpeedUnit}};起始日期：{{item1.startTime.slice(0,10)}};连续天数：{{item1.days}}</span></div>
                        </div>
                        <div v-for="(item2,index2) in item1.mzPrescriptionDetailSY" :key="index2" style="margin-left:15px;margin-top:5px">
                            <div></div>
                            <div><span> 名称：{{item2.drugOrgName}};皮试：{{item2.st}};单次剂量：{{item2.dosage}}{{item2.dosageUnit}};开药量：{{item2.defaultDosage}}{{item2.defaultDosageUnit}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
            <div>医嘱:{{ patientDetail.mzPrescriptionOut.doctorAdvice ? patientDetail.mzPrescriptionOut.doctorAdvice : '--' }}</div>
            <div class="PrintBoxTitleLine"></div>
            <div class="PrintBoxTitle2">
                <div>医师：——————————————————————</div>
                <div>调配药师/士：——————————————————————</div>
                <div>药品金额：——————————————————————</div>
            </div>
            <div>
                <div>审核药师：——————————————————————</div>
            </div>
            <div>
                <div>开具日期：</div>
            </div>
            <div class="PrintBoxTitleLine">
                
            </div>
            <div>
                注意处方有效期3天、擅自下载打印无效
            </div>
        </div>
        <div class="titleTopCenter noflex-l-s">
             <div class="titleTopCenterLeft">
                <div :class='userInfo.isAuditDiagnose == "True" ? "titleTopCenterLeftBox" : "titleTopCenterLeftBoxno"' @click="goDoctor">处方审核</div>
             </div>
            <div class="titleTopCenterCenter">
                <div class="titleTopCenterCenterTop titleTopCenterCenterTopLine">
                    <div class="Content1Title"><i class="el-icon-back" @click="goBack"></i>病历详情</div>
                    <div class="noflex-l-s" style="margin-top:40px ">
                        <div  style="width:40%">
                            <div class="titleTopright1 titleSize22">{{ patientDetailAll.name }}</div>
                            <div class="flex-l" style="padding-top:30px">
                                <div>
                                    <span>性别：</span><span>{{ patientDetailAll.sex ? '男' : '女'  }}</span>
                                </div>
                                <div style="padding:0 10%">
                                    <span>年龄：</span><span>{{ patientDetailAll.age }}</span>
                                </div>
                                <div>
                                    <i class="el-icon-phone greenColor"></i><span>{{ patientDetailAll.phone }}</span>
                                </div>
                            </div>
                        </div>
                        <div style="width:40%">
                                <div>
                                    <span>医院/诊所：</span><span>{{ patientDetail.mzPrescriptionOut.organizationName }}</span>
                                </div>
                                <div style="padding:30px 0">
                                    <span>就诊时间：</span><span>{{ patientDetail.mzPrescriptionOut.addTime | timefilters }}</span>
                                </div>
                                <div>
                                    <span>看诊医生：</span><span>{{ patientDetail.mzPrescriptionOut.userName }}</span>
                                </div>
                        </div>
                        <!-- <div style="width:40%" v-if=" patientDetail.mzPrescriptionOut.mzPrescriptionCY.length == 0 && patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length == 0">
                                <div>
                                    <span>医院/诊所：</span><span>{{ patientDetail.mzPrescriptionOut.mzPrescriptionYP[0].organizationName }}</span>
                                </div>
                                <div style="padding:30px 0">
                                    <span>就诊医生：</span><span>{{ patientDetail.mzPrescriptionOut.mzPrescriptionYP[0].addTime | timefilters }}</span>
                                </div>
                                <div>
                                    <span>看诊医生：</span><span>{{ patientDetail.mzPrescriptionOut.mzPrescriptionYP[0].userName }}</span>
                                </div>
                        </div>
                        <div style="width:40%" v-if=" patientDetail.mzPrescriptionOut.mzPrescriptionCY.length == 0 && patientDetail.mzPrescriptionOut.mzPrescriptionYP.length == 0">
                                <div>
                                    <span>医院/诊所：</span><span>{{ patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[0].organizationName }}</span>
                                </div>
                                <div style="padding:30px 0">
                                    <span>就诊医生：</span><span>{{ patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[0].addTime | timefilters }}</span>
                                </div>
                                <div>
                                    <span>看诊医生：</span><span>{{ patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[0].userName }}</span>
                                </div>
                        </div> -->
                        <div style="width:20%;text-align: right;">
                            <div class="tagEdit" v-if="userInfo.IsAuditDiagnose">编辑</div>
                        </div>
                        <div class="imgBox" v-if="patientDetail.mzPrescriptionOut.auditStatus == 0"> 
                            <img :src="imgurl1" alt="" srcset="">
                        </div>
                        <div class="imgBox" v-if="patientDetail.mzPrescriptionOut.auditStatus == 1"> 
                            <img :src="imgurl0" alt="" srcset="">
                        </div>
                        <div class="imgBox" v-if="patientDetail.mzPrescriptionOut.auditStatus == -1"> 
                            <img :src="imgurl2" alt="" srcset="">
                        </div>
                    </div>
                    <div class="Content1Title">诊断详情:</div>
                </div>
                <div class="titleTopCenterCenterTop">
                        <div class="noflex-l-s">
                            <div  class="titleTopLeftTable" style=" border: none">
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">诊断</div>
                                </div>
                                <div style="margin-left:15px;margin-top:5px">
                                    <div></div>
                                    <div><span>{{ patientDetailAll.patientDiagnose[0].diseaseDiagnose }}</span></div>
                                </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">病史</div>
                                </div>
                                <div class="flex-l" style="margin-left:15px;margin-top:5px">
                                    <div class="titleTopLeftTableTag1"></div>
                                    <div style="color:#999999">家族病史</div>
                                    <div  style="margin-left:15px;"><span>{{ patientDetailAll.patientDiagnose[0].family ? patientDetailAll.patientDiagnose[0].family : '-'}}</span></div>
                                </div>
                                <div class="flex-l" style="margin-left:15px;margin-top:5px">
                                    <div class="titleTopLeftTableTag1"></div>
                                    <div style="color:#999999">过敏史</div>
                                    <div style="margin-left:15px;"><span>{{patientDetailAll.patientDiagnose[0].irritability ? patientDetailAll.patientDiagnose[0].irritability : '-'}}</span></div>
                                </div>
                                <div class="flex-l" style="margin-left:15px;margin-top:5px">
                                    <div class="titleTopLeftTableTag1"></div>
                                    <div style="color:#999999">既往史</div>
                                    <div style="margin-left:15px;"><span>{{patientDetailAll.patientDiagnose[0].past ? patientDetailAll.patientDiagnose[0].past : '-'}}</span></div>
                                </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">主诉</div>
                                </div>
                                <div style="margin-left:15px;margin-top:5px">
                                    <div></div>
                                    <div>{{ patientDetailAll.patientDiagnose[0].main ? patientDetailAll.patientDiagnose[0].main : '-' }}</div>
                                </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">体格检查</div>
                                </div>
                                <div style="margin-left:15px;margin-top:5px">
                                    <div class="flex-l">
                                        <div style="margin-right:5px"><span>体温：</span><span>{{ patientDetailAll.patientDiagnose[0].temperature ? patientDetailAll.patientDiagnose[0].temperature : '-' }} ℃</span></div>
                                        <div style="margin-right:5px"><span>体重：</span><span>{{ patientDetailAll.patientDiagnose[0].weight ? patientDetailAll.patientDiagnose[0].weight : '-' }}kg</span></div>
                                        <div style="margin-right:5px"><span>心率：</span><span>{{ patientDetailAll.patientDiagnose[0].heartRate ? patientDetailAll.patientDiagnose[0].heartRate : '-' }}次/分</span></div>
                                        <div style="margin-right:5px"><span>血压：</span><span>{{ patientDetailAll.patientDiagnose[0].heightTension ? patientDetailAll.patientDiagnose[0].heightTension : '-' }}/{{ patientDetailAll.patientDiagnose[0].lowTension ? patientDetailAll.patientDiagnose[0].lowTension : '-' }}mmHg</span></div>
                                    </div>
                                    <div class="flex-l">
                                        <div style="margin-right:5px"><span>身高：</span><span>{{ patientDetailAll.patientDiagnose[0].height ? patientDetailAll.patientDiagnose[0].height : '-' }}CM</span></div>
                                        <div style="margin-right:5px"><span>呼吸：</span><span>{{ patientDetailAll.patientDiagnose[0].breathe ? patientDetailAll.patientDiagnose[0].breathe : '-' }}次/分</span></div>
                                        <div style="margin-right:5px"><span>脉搏：</span><span>{{ patientDetailAll.patientDiagnose[0].pulse ? patientDetailAll.patientDiagnose[0].pulse : '-' }}次/分</span></div>
                                        <div style="margin-right:5px"><span>血压：</span><span>{{ patientDetailAll.patientDiagnose[0].heightTension ? patientDetailAll.patientDiagnose[0].heightTension : '-' }}次/分</span></div>
                                    </div>
                                    <div class="flex-l">
                                        <div style="margin-right:5px"><span>其他：</span><span>{{ patientDetailAll.patientDiagnose[0].otherCheck ? patientDetailAll.patientDiagnose[0].otherCheck : '-' }}</span></div>
                                    </div>
                                    
                                </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">症状图片</div>
                                </div>
                                <!-- <div style="margin-left:15px;margin-top:5px">
                                    <div style="margin-bottom:10px">最多上传5张图片，最多不超过20M</div>
                                    <el-upload
                                            class="avatar-uploader"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :show-file-list="false"
                                            :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                            <i v-else class="el-icon-upload2 avatar-uploader-icon"></i>
                                    </el-upload>
                                </div> -->
                            </div>
                            <div  class="titleTopLeftTable" style=" border: none">
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">检查治疗项</div>
                                </div>
                                <div v-if="patientDetail.mzCheckItems.length != 0"> 
                                    <div style="margin-left:15px;margin-top:5px" v-for="(item,index) in patientDetail.mzCheckItems" :key="index">
                                        <div></div>
                                        <div><span>{{ item.name }}</span></div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div style="margin-left:15px;margin-top:5px">
                                        <div></div>
                                        <div><span>--</span></div>
                                    </div>
                                </div>
                     
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">处方</div>
                                <div v-if="patientDetail.mzPrescriptionOut.auditStatus == 0" style="color:#00AFB5;margin-left: 10px;" @click="goAddClick">审核修改</div>
                                </div>

                                <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0"> 
                                    <div v-for="(item,index) in patientDetail.mzPrescriptionOut.mzPrescriptionYP" :key="index">
                                        <div class="flex-l" style="margin-left:15px;margin-top:5px" >
                                            <div class="titleTopLeftTableTag1"></div>
                                            <div style="color:#999999">{{ item.prescriptionName }}</div>
                                        </div>
                                        <div style="margin-left:15px;margin-top:5px" v-for="(item1,index1) in item.mzPrescriptionDetail" :key="index1">
                                            <div></div>
                                            <div><span>名称：{{item1.drugOrgName}};用量：{{item1.weight}};炮制： {{item1.usage}};备注：{{item1.remark}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0"> 
                                    <div v-for="(item,index) in patientDetail.mzPrescriptionOut.mzPrescriptionCY" :key="index">
                                        <div class="flex-l" style="margin-left:15px;margin-top:5px" >
                                            <div class="titleTopLeftTableTag1"></div>
                                            <div style="color:#999999">{{ item.prescriptionName }}</div>
                                        </div>
                                        <div style="margin-left:15px;margin-top:5px" v-for="(item1,index1) in item.mzPrescriptionDetail" :key="index1">
                                            <div></div>
                                            <div><span>名称：{{item1.drugOrgName}};组号：{{item1.groupNumber}};用法：{{item1.usage}};用药频率： {{item1.frequency}};单次剂量：{{item1.dosage}}{{item1.dosageUnit}};开药量：{{item1.defaultDosage}}{{item1.defaultDosageUnit}}</span></div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0"> 
                                    <div v-for="(item,index) in patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup" :key="index">
                                        <div class="flex-l" style="margin-left:15px;margin-top:5px" >
                                            <div class="titleTopLeftTableTag1"></div>
                                            <div style="color:#999999">{{ item.prescriptionName }}</div>
                                        </div>
                                        <div  v-for="(item1,index1) in item.mzPrescriptionGroups" :key="index1">
                                            <div style="margin-left:15px;margin-top:5px">
                                                <div></div>
                                                <div><span>组{{ index1 + 1 }}  用法：{{item1.usage}};用药频率：{{item1.frequency}};滴速 {{item1.drippingSpeed}}{{item1.drippingSpeedUnit}};起始日期：{{item1.startTime.slice(0,10)}};连续天数：{{item1.days}}</span></div>
                                            </div>
                                            <div v-for="(item2,index2) in item1.mzPrescriptionDetailSY" :key="index2" style="margin-left:15px;margin-top:5px">
                                                <div></div>
                                                <div><span> 名称：{{item2.drugOrgName}};皮试：{{item2.st}};单次剂量：{{item2.dosage}}{{item2.dosageUnit}};开药量：{{item2.defaultDosage}}{{item2.defaultDosageUnit}}</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">医嘱</div>
                                </div>

                                    <div style="margin-left:15px;margin-top:5px">
                                        <div></div>
                                        <div><span>{{ patientDetail.mzPrescriptionOut.doctorAdvice ? patientDetail.mzPrescriptionOut.doctorAdvice : '--' }}</span></div>
                                    </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">附加费用</div>
                                </div>

                                <div v-if="patientDetail.mzOtherCharges.length != 0"> 
                                    <div style="margin-left:15px;margin-top:5px" v-for="(item,index) in patientDetail.mzOtherCharges" :key="index">
                                        <div></div>
                                        <div><span>{{ item.chargeName }}</span></div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div style="margin-left:15px;margin-top:5px">
                                        <div></div>
                                        <div><span>--</span></div>
                                    </div>
                                </div>
                                <div class="flex-l" style="margin-top:10px">
                                    <div class="titleTopLeftTableTag"></div>
                                    <div style="color:#999999">处方图片</div>
                                </div>
                                <!-- <div style="margin-left:15px;margin-top:5px">
                                    <div style="margin-bottom:10px">最多上传5张图片，最多不超过20M</div>
                                    <el-upload
                                            class="avatar-uploader"
                                            action="https://jsonplaceholder.typicode.com/posts/"
                                            :show-file-list="false"
                                            :on-success="handleAvatarSuccess"
                                            :before-upload="beforeAvatarUpload">
                                            <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                            <i v-else class="el-icon-upload2 avatar-uploader-icon"></i>
                                    </el-upload>
                                </div> -->
                            </div>
                        </div>
                </div>
            </div>
        </div>
        <el-drawer
            title="审核处方"
            :visible.sync="isdrawer"
            direction="rtl"
            size="800px"
            >   
           
             <div class="table3Box" v-for="(itemPre,indexPre) in patientFormPreList" :key="indexPre">
                            <div class="flex-l titleSize14" style="margin-bottom:20px">
                                <div class="">方剂处方：</div> <el-input v-model="itemPre.prescriptionName" placeholder="请输入中药方剂名称" style="width: 40%;"></el-input>
                            </div>
                                <el-table :data="itemPre.mzPrescriptionDetail" border >
                                    <el-table-column
                                        type="index"
                                        width="50">
                                    </el-table-column>
                                    <el-table-column prop="drugOrgId" align="center" label="成分">
                                        <template slot-scope="scope">
                                            <el-select v-model="scope.row.drugOrgId"
                                                    filterable remote size="small" reserve-keyword placeholder="请输入成分"
                                                    ref="selectLabel" :remote-method="SelectComposition"
                                                    @change="ConstituteChange($event,scope.row)">
                                                    <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                                        :label="item.drugOrgName" :value="item.drugOrgId">
                                                    </el-option>
                                            </el-select> 
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="weight" align="center" label="用量">
                                        <template slot-scope="scope">
                                                <el-input  v-model="scope.row.weight"  size="small"
                                                    placeholder="用量(g)">
                                                    <!-- <el-select style=" width: 65px;" v-if="scope.row.dosageList.dosageUnit" slot="append" v-model="scope.row.dosageList.dosageUnit" size='mini' placeholder="单位">
                                                        <el-option v-for="item in kgOption" :key="item.value"
                                                          :label="item.value" :value="item.value"> </el-option>
                                                    </el-select> -->
                                                </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="usage" align="center" label="炮制方法">
                                        <template slot-scope="scope">
                                           
                                            <el-select  v-model="scope.row.usage" size='mini' placeholder="炮制方法">
                                                <el-option v-for="item in remarkOption" :key="item"
                                                    :label="item" :value="item"> </el-option>
                                            </el-select>
                                           
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="remark" align="center" label="备注">
                                        <template slot-scope="scope">
                                                <el-input  v-model="scope.row.remark" size="small"
                                                    placeholder="输入备注信息">
                                                </el-input>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="100" align="center">
                                        <template slot-scope="scope">
                                               <div class="flex-l">
                                                <!-- <div>￥{{scope.row.tradingPrice ? scope.row.weight * 1 * scope.row.tradingPrice : '0.00'}}</div> -->
                                                <i class="el-icon-delete" style="color:#01C2AC;margin-left:10px;font-size:22px" @click="gondeletePre(indexPre,scope.$index)"></i>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <div class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPre(indexPre)">添加中药材</div>
                                <div class="flex-l" style="margin-top:15px">
                                    剂数: <el-input-number class="stepInput" v-model="itemPre.dosage" controls-position="right" :min="1"></el-input-number>剂
                                </div>
                                    
                                    <div class="flex-l" style="margin-top:15px">
                                        每日剂量: <el-input-number class="stepInput" v-model="itemPre.dosageNumber" controls-position="right" :min="1"></el-input-number>剂
                                    </div>
                                        <div class="flex-l" style="margin-top:15px">
                                            用药频率:    <el-select  v-model="itemPre.frequency" placeholder="用药频率">
                                                <el-option v-for="item in frequencyOption" :key="item"
                                                    :label="item" :value="item"> </el-option>
                                            </el-select>
                                        </div>
                                        <div class="flex-l" style="margin-top:15px">
                                            用法：  <el-select  v-model="itemPre.usage" placeholder="用法">
                                                <el-option v-for="item in usageOption" :key="item"
                                                    :label="item" :value="item"> </el-option>
                                            </el-select>
                                        </div>
                                      
                                        <div class="flex-l" style="margin-top:15px">
                                            服务要求: <el-input  v-model="itemPre.request" style="width: 60%;"></el-input> 
                                        </div>
                                    <!-- <el-button  @click="patientFormPreResetForm('patientFormPre')">重置</el-button> -->     
                            </div>
                        <div class="table3Box" v-for="(itemPreCY,indexPreCY) in patientFormPreCY" :key="indexPreCY">
                            <div class="flex-l titleSize14" style="margin-bottom:20px">
                                <div class="">成药处方：</div> <el-input v-model="itemPreCY.prescriptionName" placeholder="请输入中药方剂名称" style="width: 40%;"></el-input>
                            </div>
                            <el-table :data="itemPreCY.mzPrescriptionDetail" border>
                                <el-table-column prop="drugOrgName" align="center" label="药品名称">
                                    <template slot-scope="scope">
                                    <el-select v-model="scope.row.drugOrgId"
                                        filterable remote size="small" reserve-keyword placeholder="请输入成分"
                                        ref="selectLabel" :remote-method="SelectComposition"
                                        @change="ConstituteChange($event,scope.row)">
                                        <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                            :label="item.drugOrgName" :value="item.drugOrgId">
                                        </el-option>
                                    </el-select>
                                    </template>
                            
                                </el-table-column>
                                <el-table-column prop="groupNumber" align="center" label="组号" width="120">
                                    <template slot-scope="scope">
                                            <el-input  v-model="scope.row.groupNumber" size="small"
                                                placeholder="输入组号">
                                            </el-input> 
                                    </template>
                                </el-table-column>
                                <el-table-column prop="usage" align="center" label="用法" width="130">
                                    <template slot-scope="scope">
                                        <el-select  v-model="scope.row.usage" size='mini' placeholder="用法">
                                            <el-option v-for="item in remarkOption" :key="item"
                                                :label="item" :value="item"> </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="frequency" align="center" label="用药频率" width="150">
                                    <template slot-scope="scope">
                                        <el-select  v-model="scope.row.frequency" size='mini' placeholder="用药频率">
                                            <el-option v-for="item in frequencyOption" :key="item"
                                                :label="item" :value="item"> </el-option>
                                        </el-select>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.dosageList.dosage" size="small"   placeholder="单次剂量">
                                                <el-select style=" width: 75px;" slot="append" v-model="scope.row.dosageList.dosageUnit" size='mini' placeholder="单位">
                                                    <el-option v-for="item in kgOption" :key="item"
                                                        :label="item" :value="item"> </el-option>
                                                </el-select>
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="defaultDosage" align="center" label="开药量" width="160">
                                    <template slot-scope="scope">
                                        <el-input  v-model="scope.row.defaultDosageList.defaultDosage" size="small"   placeholder="开药量">
                                            <el-select style="width: 75px;" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size='mini' placeholder="单位">
                                                <el-option v-for="item in kgOption" :key="item"
                                                    :label="item" :value="item"> </el-option>
                                            </el-select>
                                        </el-input>
                                    </template>
                                </el-table-column>
                                <el-table-column width="100" align="center">
                                    <template slot-scope="scope">
                                        <div class="flex-l">
                                            <i class="el-icon-delete" style="color:#01C2AC;margin-left:10px;font-size:22px" @click="gondeletePreCY(indexPreCY,scope.$index)"></i>
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                                <div class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPreCY(indexPreCY)">添加中药材</div>

                                    <!-- <el-button  @click="patientFormPreResetForm('patientFormPre')">重置</el-button> -->     
                            </div> 
                        <div class="table3Box" v-for="(itemPreSY,indexPreSY) in patientFormPreSY" :key="indexPreSY">

                        
                            <div class="flex-l titleSize14" style="margin-bottom:20px">
                                <div class="">输液处方：</div> <el-input v-model="itemPreSY.prescriptionName" placeholder="输液处方：" style="width: 40%;"></el-input>
                            </div>
                                <div v-for="(itemComSY1, indexComSY1) in itemPreSY.zucompositionList" :key="indexComSY1" style="margin-top:20px">
                                    <div class="flex-l titleSize14">
                                        <div class="">组{{indexComSY1 * 1 + 1}}</div>
                                        <div class="flex-l titleTopright1 titleSize14" >
                                            <div style="margin-left:15px" @click="godeleteSYzuList(indexPreSY,indexComSY1)">删除改组</div>
                                        </div>
                                    </div>
                                    <el-table :data="itemComSY1.mzPrescriptionTitle" border>
                                        <el-table-column prop="usage" align="center" label="用法">
                                            <template slot-scope="scope">
                                                <el-select  v-model="scope.row.usage" size='mini' placeholder="用法">
                                                    <el-option v-for="item in remarkOption" :key="item"
                                                        :label="item" :value="item"> </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="frequency" align="center" label="用药频率">
                                            <template slot-scope="scope">
                                                <el-select  v-model="scope.row.frequency" size='mini' placeholder="用药频率">
                                                    <el-option v-for="item in frequencyOption" :key="item"
                                                        :label="item" :value="item"> </el-option>
                                                </el-select>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="drippingSpeedList" align="center" label="滴速">
                                            <template slot-scope="scope">
                                                    <el-input  v-model="scope.row.drippingSpeedList.drippingSpeed" size="small"   placeholder="单次剂量">
                                                            <el-select style=" width: 75px;" slot="append" v-model="scope.row.drippingSpeedList.drippingSpeedUnit" size='mini' placeholder="单位">
                                                                <el-option v-for="item in SpeeOption" :key="item"
                                                                    :label="item" :value="item"> </el-option>
                                                            </el-select>
                                                    </el-input>
                                                </template>
                                        </el-table-column>
                                        <el-table-column prop="startTime" align="center" label="起始日期">
                                            <template slot-scope="scope">
                                                    <el-date-picker
                                                        v-model="scope.row.startTime"
                                                        type="date"
                                                        placeholder="选择日期"
                                                       >
                                                     
                                                    </el-date-picker>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="days" align="center" label="连续天数">
                                            <template slot-scope="scope">
                                                    <el-input  v-model="scope.row.days" size="small"
                                                        placeholder="天数">
                                                    </el-input>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                        <el-table :data="itemComSY1.mzPrescriptionDetail" border style="margin-top:10px">
                                            <el-table-column prop="compositionName" align="center" label="药品名称">
                                                <template slot-scope="scope">
                                        
                                                    <el-select v-model="scope.row.drugOrgId"
                                                        filterable remote size="small" reserve-keyword placeholder="请输入成分"
                                                        ref="selectLabel" :remote-method="SelectComposition"
                                                        @change="ConstituteChange($event,scope.row)">
                                                        <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId"
                                                            :label="item.drugOrgName" :value="item.drugOrgId">
                                                        </el-option>
                                                    </el-select>                
                                                   
                                                </template>
                                        
                                            </el-table-column>
                                            <el-table-column prop="st" align="center" label="皮试">
                                                <template slot-scope="scope">
                                                        <el-input  v-model="scope.row.st" size="small"
                                                            placeholder="皮试">
                                                        </el-input>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                                                    <template slot-scope="scope">
                                                        <el-input  v-model="scope.row.dosageList.dosage" size="small"   placeholder="单次剂量">
                                                                <el-select style=" width: 75px;" slot="append" v-model="scope.row.dosageList.dosageUnit" size='mini' placeholder="单位">
                                                                    <el-option v-for="item in kgOption" :key="item"
                                                                        :label="item" :value="item"> </el-option>
                                                                </el-select>
                                                        </el-input>
                                                    </template>
                                            </el-table-column>
                                            <el-table-column prop="defaultDosageList" align="center" label="开药量" width="160">
                                                <template slot-scope="scope">
                                                    <el-input  v-model="scope.row.defaultDosageList.defaultDosage" size="small"   placeholder="开药量">
                                                            <el-select style="width: 75px;" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size='mini' placeholder="单位">
                                                                <el-option v-for="item in kgOption" :key="item"
                                                                    :label="item" :value="item"> </el-option>
                                                            </el-select>
                                                    </el-input>
                                                </template>
                                            </el-table-column>
                                                <el-table-column width="100" align="center">
                                                    <template slot-scope="scope">
                                                <div class="flex-l">
                                                    <i class="el-icon-delete" style="color:#01C2AC;margin-left:10px;font-size:22px" @click="godeletSYzuDrugList(indexPreSY,indexComSY1,scope.$index)"></i>
                                                </div>
                                            </template>
                                            </el-table-column>
                                        </el-table>
                                        <div class="flex-l titleSize14">
                                            <div class="titleSize14 titleTopright1" @click="goaddSYzuDrugList(indexPreSY,indexComSY1)">添加药品</div>
                                        <div class="titleSize14 titleTopright1" @click="goaddSYzuList(indexPreSY)" style="margin-left:15px">添加组</div>
                                        </div>
                                    </div>
                            </div>
                            <div class="flex-l" style="margin:40px 20px;">
                                <el-button  type="primary" @click="goSuccess()">审核通过</el-button>
                                <el-button  type="primary" @click="gofail()">审核拒绝</el-button>
                            </div>
                          
        </el-drawer>

    </div>
 
</template>
<script>
import intNavheader from "../../components/intNavheader";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import printJS from 'print-js'
import html2canvas from 'html2canvas'
export default {
    components: {
        intNavheader
    },
    data(){
        var drugpatient = new patientMain(this.TokenClient, this.Services.Drug)
        var patient = new patientMain(this.TokenClient, this.Services.Authorization)
        return {
            patientDomain: patient,
            drugpatientDomain: drugpatient,
            userInfo: this.$store.state.localData.userInfo,
            patientDetail:{},
            patientDetailAll:{},
            addtoNew:false,
            inputValue:'',
            inputValue2:'',
            num:'',
            activeName: 'first',
            isdrawer:false,
            patientFormPreList:[
            ],
            patientFormPreCY:[],
            patientFormPreSY:[],
            remarkOption:["口服", "外用", "滴眼", "静脉注射", "静脉滴注", "肌内注射", "皮下注射", "皮内注射", "腔内注射", "椎管注射", "动脉注射", "心内注射", "球内注射", "皮下埋植", "口腔喷雾", "口腔吸入", "口腔黏膜给药", "滴鼻", "鼻腔喷雾", "鼻饲", "鼻腔吸入", "滴耳", "眼科外用", "涂于眼睑内", "含漱", "含服", "舌下含服", "直肠给药", "直肠塞入", "肛门涂抹", "阴道给药", "阴道塞入", "阴道擦洗"],
            frequencyOption:["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
            usageOption:["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
            kgOption: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
            SpeeOption:["g/分钟","ml/分钟","ml/小时","分钟/次","ug/分钟","滴/分钟"],
            EditDrugCompositio:[],
            imgurl0:require('../../assets/img/state0.png'),
            imgurl1:require('../../assets/img/state1.png'),
            imgurl2:require('../../assets/img/state2.png'),
            isPrint:false
        }
    },
    mounted(){
        this.getdetailData()
    },
    methods:{
        goPrint() {
            this.isPrint = true
            html2canvas(this.$refs.print, {
                allowTaint: true,
                taintTest: false,
                useCORS: true,
                dpi: window.devicePixelRatio * 4,
                scale: 4
            }).then((canvas) => {
                // console.log(canvas)
                // let contentWidth = canvas.width
                // let contentHeight = canvas.height
                // let imgWidth = 595.2
                // let imgheight = 595.2/contentWidth * contentHeight
                const url = canvas.toDataURL()
                printJS({
                printable: url, // 要打印的id
                type: 'image',
                style: '@page{size:auto;margin: 0cm 1cm 0cm 1cm;}',//去除页眉页脚
                imgWidth,
                imgheight
                })
            this.isPrint = false
            })
        },
        getDrugCompositio(){
        var _this = this
        _this.drugpatientDomain.GetDrugByOrgInTop10('%20',
            function (data) {
                for (let i=0; i<data.data.length;i++) {
                    _this.EditDrugCompositio.push({
                        drugOrgId:data.data[i].id,
                        drugOrgName:data.data[i].mzDrug.drugName,
                        drug:data.data[i]
                    })
                }
                // _this.EditDrugCompositio = data.data.forEach(function (item) {
                //     // var label = `[${item.categoryName}]${item.compositionName}`;
                //     var label = `${item.mzDrug.drugName}`;
                //     // if (item.compositionAlias) label += `${item.compositionAlias}`;
                //     return {
                //         drugOrgName: label,
                //         drugOrgId: item.id,
                //         drug:item
                //     };
                // });
            },
            function (err) {  
                _this.EditDrugCompositio = []
                console.log(err);
                });
    },
        ConstituteChange(val, item) {
                this.EditDrugCompositio.map((res) => {        
                    if (res.drugOrgId == val) {
                        item.drugOrgName = res.drugOrgName ;
                        item.tradingPrice = res.drug.tradingPrice;
                        item.dosage = res.drug.dosage ? res.drug.dosage : '';
                        item.usage = res.drug.usage ? res.drug.usage : '';
                        item.remark = res.drug.remark ? res.drug.remark : ''
                    }

                });
            },
            gonaddPre(index){
            let row = {
                drugOrgId:'',
                drugOrgName:'',
                dosage:'',
                dosageUnit:'g',
                usage:'',
                weight:'',
                remark:''
            }
            this.patientFormPreList[index].mzPrescriptionDetail.push(row)
        },
        gondeletePre(index,index1){
            this.patientFormPreList[index].mzPrescriptionDetail.splice(index1, 1);
        },
               gonaddPreCY(index){
                let row =    {
                        drugOrgId:'',
                        drugOrgName:'',
                        dosageList:{
                            dosage:'',
                            dosageUnit:'',
                        },
                        defaultDosageList:{
                            defaultDosage:'',
                            defaultDosageUnit:'',
                        },
                        groupNumber:'',
                        frequency:'',
                        usage:'',
                        remark:'',
                        
                    }
                this.patientFormPreCY[index].mzPrescriptionDetail.push(row)
            },
            gondeletePreCY(index1,index){
                this.patientFormPreCY[index1].mzPrescriptionDetail.splice(index, 1);
            },
            goaddSYzuList(index){
                let row = 
                    {                
                        prescriptionName:'组',
                        mzPrescriptionTitle:[{
                            usage:'',
                        frequency:'',
                        drippingSpeedList:{
                            drippingSpeed:'',
                            drippingSpeedUnit:''
                        },
                        
                        startTime:'',
                        days:'',
                        }],
                        mzPrescriptionDetail:[{
                            drugOrgId:'',
                            drugOrgName:'',
                            st:'',
                            dosageList:{
                            dosage:'',
                            dosageUnit:'',
                            },
                            defaultDosageList:{
                                defaultDosage:'',
                                defaultDosageUnit:'',
                            },

                        }]
                    }
                this.patientFormPreSY[index].zucompositionList.push(row)
            },
            godeleteSYzuList(index,index1){
                this.patientFormPreSY[index].zucompositionList.splice(index1, 1)
            },
            goaddSYzuDrugList(index,indexComSY1){
                let row = {
                    drugOrgId:'',
                    drugOrgName:'',
                    st:'',
                    dosageList:{
                    dosage:'',
                    dosageUnit:'',
                    },
                    defaultDosageList:{
                        defaultDosage:'',
                        defaultDosageUnit:'',
                    },

                }
                this.patientFormPreSY[index].zucompositionList[indexComSY1].mzPrescriptionDetail.push(row)
            },
            godeletSYzuDrugList(index1,index2,index){
                this.patientFormPreSY[index1].zucompositionList[index2].mzPrescriptionDetail.splice(index, 1)
            },
        SelectComposition(val) {
            var _this = this;
            _this.drugpatientDomain.GetDrugByOrgInTop10(val,
                function (data) {
                    _this.EditDrugCompositio = data.data.map(function (item) {
                        // var label = `[${item.categoryName}]${item.compositionName}`;
                        // var label = `${item.mzDrug.drugName}（库存${item.inventoryNumber}-${item.mzDrug.specification}/${item.mzDrug.specificationUnit}-${item.mzDrug.productFactory}）`;
                        var label = `${item.mzDrug.drugName}`;
                        // if (item.compositionAlias) label += `${item.compositionAlias}`;
                        return {
                            drugOrgName: label,
                            drugOrgId: item.id,
                            drug:item
                        };
                    });
                },
                function (err) {  
                    _this.EditDrugCompositio = []
                    console.log(err);
                });
        },
        goAddClick(){
            this.getDrugCompositio()
            this.isdrawer = true
        },
        goDoctor(){
            if (this.userInfo.IsAuditDiagnose == "True") {
                this.$router.push({
                    name:'PremangePatientList'
                })
            } else {
                this.$message({
                    message: '暂无审方权限,请联系管理员添加',
                    type: 'warning'
                });
            }
            // this.doctorDialogVisible = true
            // this.GetAuditDoctorData()
        },
        getdetailData(){
            var _this = this
            _this.drugpatientDomain.MZPrescriptionDetail(_this.$route.query.id, function (data) {
                    _this.patientDetail = data.data
                    _this.patientDomain.GetPatientDetail(data.data.mzPrescriptionOut.patientId ,function (data) {
                        if (data.data.patientDiagnose.length > 0) {
                            for (let i=0; i<data.data.patientDiagnose.length; i++) {
                                let f = data.data.patientDiagnose[i].addTime.split('T')
                                data.data.patientDiagnose[i].addTime = f[0]
                            }
                        }
                        _this.patientDetailAll = data.data
                        if (_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP.length > 0) {
                            for (let i=0; i<_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP.length; i++) {
                                _this.patientFormPreList.push({
                                    id :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].id,
                                    usage : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].usage,
                                    prescriptionName :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].prescriptionName,
                                    dosageNumber :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosageNumber,
                                    dosage :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosage,
                                    frequency :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].frequency,
                                    type :  0,
                                    prescriptionType :  1,
                                    isSelef: true ,
                                    waitAuditUserId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].waitAuditUserId,
                                    waitAuditUserName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].waitAuditUserName,
                                    mzPrescriptionDetail:[],
                                    patientId:_this.patientDetail.mzPrescriptionOut.patientId,
                                    patientName:_this.patientDetail.mzPrescriptionOut.patientName,
                                    patientDiagnoseId:_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                                    diseaseDiagnose:_this.patientDetail.mzPrescriptionOut.diseaseDiagnose
                                })
                                for (let j=0; j<_this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail.length; j++) {
                                    for (let m=0; m<_this.patientFormPreList.length; m++) {
                                        _this.patientFormPreList[m].mzPrescriptionDetail.push({
                                            drugOrgId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgId,
                                            drugOrgName : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].drugOrgName,
                                            dosage : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].dosage,
                                            dosageUnit : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].dosageUnit,
                                            usage : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].usage,
                                            weight : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].weight,
                                            remark : _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].mzPrescriptionDetail[j].remark
                                        })
                                    }
                                }
                            }
                        }
                        if(_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY.length > 0) {
                            for (let i=0; i<_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY.length; i++) {
                                _this.patientFormPreCY.push({
                                    id :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].id,
                                    prescriptionName :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].prescriptionName,
                                    type :  1,
                                    prescriptionType :  1,
                                    isSelef: true ,
                                    waitAuditUserId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].waitAuditUserId,
                                    waitAuditUserName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].waitAuditUserName,
                                    patientId:_this.patientDetail.mzPrescriptionOut.patientId,
                                    patientName:_this.patientDetail.mzPrescriptionOut.patientName,
                                    patientDiagnoseId:_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                                    diseaseDiagnose:_this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                                    mzPrescriptionDetail:[]
                                })
                                for (let j=0; j<_this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail.length; j++) {
                                    for (let m=0; m<_this.patientFormPreCY.length; m++) {
                                        _this.patientFormPreCY[m].mzPrescriptionDetail.push({
                                            drugOrgId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgId,
                                            drugOrgName : _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].drugOrgName,
                                            dosageList:{
                                                dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].dosage,
                                                dosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].dosageUnit
                                            },
                                            defaultDosageList:{
                                                defaultDosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].defaultDosage,
                                                defaultDosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].defaultDosageUnit
                                            },
                                            groupNumber : _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].groupNumber,
                                            frequency : _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].frequency,
                                            usage : _this.patientDetail.mzPrescriptionOut.mzPrescriptionCY[i].mzPrescriptionDetail[j].usage

                                        })
                                    }
                            }
                            }
                        }
                        if(_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length > 0) {
                            for (let i=0; i<_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup.length; i++) {
                                _this.patientFormPreSY.push({
                                    id :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].id,
                                    prescriptionName :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].prescriptionName,
                                    dosageNumber :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosageNumber,
                                    dosage :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].dosage,
                                    frequency :  _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].frequency,
                                    type :  2,
                                    prescriptionType :  1,
                                    isSelef: true ,
                                    waitAuditUserId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].waitAuditUserId,
                                    waitAuditUserName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionYP[i].waitAuditUserName,
                                    patientId:_this.patientDetail.mzPrescriptionOut.patientId,
                                    patientName:_this.patientDetail.mzPrescriptionOut.patientName,
                                    patientDiagnoseId:_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                                    diseaseDiagnose:_this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                                    zucompositionList:[],
                                  
                                })
                                for (let j=0; j<_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups.length; j++) {
                                    for (let m=0; m<_this.patientFormPreSY.length; m++) {
                                        _this.patientFormPreSY[m].zucompositionList.push({
                                            mzPrescriptionTitle: [],
                                            mzPrescriptionDetail:[]
                                        })
                                        for(let x=0; x<_this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY.length; x++) {
                                        for (let y=0; y< _this.patientFormPreSY[m].zucompositionList.length; y++) {
                                            _this.patientFormPreSY[m].zucompositionList[y].mzPrescriptionDetail.push({
                                                drugOrgId: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgId,
                                                drugOrgName: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].drugOrgName,
                                                st: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].st,
                                                dosageList: {
                                                    dosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].dosage,
                                                    dosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].dosageUnit,
                                                },
                                                defaultDosageList: {
                                                    defaultDosage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].defaultDosage,
                                                    defaultDosageUnit: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].mzPrescriptionDetailSY[x].defaultDosageUnit,
                                                } 
                                               
                                            })
                                            _this.patientFormPreSY[m].zucompositionList[y].mzPrescriptionTitle.push({
                                                frequency: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].frequency,
                                                usage: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].usage,
                                                days: _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].days,
                                                drippingSpeedList:{
                                                    drippingSpeed : _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeed,
                                                    drippingSpeedUnit : _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeedUnit
                                                }
                                            })


                                            // _this.patientFormPreSY[m].zucompositionList[y].mzPrescriptionTitle[0].frequency = _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].frequency
                                            // _this.patientFormPreSY[m].zucompositionList[y].mzPrescriptionTitle[0].usage = _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].usage
                                            // _this.patientFormPreSY[m].zucompositionList[y].mzPrescriptionTitle[0].days = _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].days
                                            // _this.patientFormPreSY[m].zucompositionList[y].mzPrescriptionTitle[0].drippingSpeedList = {
                                            //     drippingSpeed : _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeed,
                                            //     drippingSpeedUnit : _this.patientDetail.mzPrescriptionOut.mzPrescriptionSYGroup[i].mzPrescriptionGroups[j].drippingSpeedUnit
                                            // } 
                                        }
                                    }
                                    }
                                }
                            }
                         }
                        // _this.patientFormPreSY = data.data.mzPrescriptionSY
                       
                    }, function (error) {
                        console.log(error)
                        
                    })
                }, function (error) {
                    
                })
        },
        goBack(){
            this.$router.push({
                name:'PremangePatientList'
            })
        },
        goSuccess(){
            var _this = this
            let params = []
            for (let i=0; i<_this.patientFormPreList.length; i++) {
                _this.patientFormPreList[i].isOk = true 
            }
            for (let i=0; i<_this.patientFormPreCY.length; i++) {
                _this.patientFormPreCY[i].isOk = true 
                for (let j = 0 ; j<_this.patientFormPreCY[i].mzPrescriptionDetail.length; j++) {
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosage = _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageUnit = _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosage = _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosage
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosageUnit = _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosageUnit
                }
            }
            let params2 = []
                let params1 = []
                for (let x=0;x<_this.patientFormPreSY.length; x++) {
                    params2.push(_this.patientFormPreSY[x])
                }
                for (let y=0;y<params2.length; y++) {
                    params2[y].mzPrescriptionGroup = params2[y].zucompositionList
                    params1.push({
                        mzPrescriptionDetail:params2[y].mzPrescriptionDetail,
                        mzPrescriptionGroup:[],
                        prescriptionName:params2[y].prescriptionName,
                        dosageNumber :  params2[y].dosageNumber,
                        dosage :  params2[y].dosage,
                        frequency :  params2[y].frequency,
                        type :  2,
                        prescriptionType :  1,
                        isSelef: true ,
                        waitAuditUserId: params2[y].waitAuditUserId,
                        waitAuditUserName: params2[y].waitAuditUserName,
                        patientId:_this.patientDetail.mzPrescriptionOut.patientId,
                        patientName:_this.patientDetail.mzPrescriptionOut.patientName,
                        patientDiagnoseId:_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                        diseaseDiagnose:_this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                    })
                }
                for (let z=0; z<params2.length;z++) {
                    for (let o=0; o<params2[z].zucompositionList.length; o++) {
                        for (let p=0; p<params2[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit
                        }
                        for (let w=0;w<params1.length;w++) {
                            params1[w].mzPrescriptionGroup.push({
                                usage : params2[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
                                days : params2[z].zucompositionList[o].mzPrescriptionTitle[0].days,
                                drippingSpeed : params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
                                drippingSpeedUnit : params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
                                frequency : params2[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
                                startTime : params2[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
                                mzPrescriptionDetailSY : params2[z].zucompositionList[o].mzPrescriptionDetail
                        })
                      }
                    }
                }
            for (let i=0; i<params2.length; i++) {
                params2[i].isOk = true
            }
            params = _this.patientFormPreList.concat(_this.patientFormPreCY).concat(params1)
            _this.drugpatientDomain.getAuditMZPrescription(params,
                function (data) {
                    console.log(data.data)
            },
            function (err) {  
            console.log(err);
            });
        },
        gofail(){
            var _this = this
            let params = []
            for (let i=0; i<_this.patientFormPreList.length; i++) {
                _this.patientFormPreList[i].isOk = false
            }
            for (let i=0; i<_this.patientFormPreCY.length; i++) {
                _this.patientFormPreCY[i].isOk = false
                for (let j = 0 ; j<_this.patientFormPreCY[i].mzPrescriptionDetail.length; j++) {
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosage = _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageUnit = _this.patientFormPreCY[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosage = _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosage
                    _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosageUnit = _this.patientFormPreCY[i].mzPrescriptionDetail[j].dosageList.dosageUnit
                }
            }
            let params2 = []
                let params1 = []
                for (let x=0;x<_this.patientFormPreSY.length; x++) {
                    params2.push(_this.patientFormPreSY[x])
                }
                for (let y=0;y<params2.length; y++) {
                    params2[y].mzPrescriptionGroup = params2[y].zucompositionList
                    params1.push({
                        mzPrescriptionDetail:params2[y].mzPrescriptionDetail,
                        mzPrescriptionGroup:[],
                        prescriptionName:params2[y].prescriptionName,
                        dosageNumber :  params2[y].dosageNumber,
                        dosage :  params2[y].dosage,
                        frequency :  params2[y].frequency,
                        type :  2,
                        prescriptionType :  1,
                        isSelef: true ,
                        waitAuditUserId: params2[y].waitAuditUserId,
                        waitAuditUserName: params2[y].waitAuditUserName,
                        patientId:_this.patientDetail.mzPrescriptionOut.patientId,
                        patientName:_this.patientDetail.mzPrescriptionOut.patientName,
                        patientDiagnoseId:_this.patientDetail.mzPrescriptionOut.patientDiagnoseId,
                        diseaseDiagnose:_this.patientDetail.mzPrescriptionOut.diseaseDiagnose,
                    })
                }
                for (let z=0; z<params2.length;z++) {
                    for (let o=0; o<params2[z].zucompositionList.length; o++) {
                        for (let p=0; p<params2[z].zucompositionList[o].mzPrescriptionDetail.length; p++) {
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosage
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].defaultDosageList.defaultDosageUnit
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosage = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosage
                            params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageUnit = params2[z].zucompositionList[o].mzPrescriptionDetail[p].dosageList.dosageUnit
                        }
                        for (let w=0;w<params1.length;w++) {
                            params1[w].mzPrescriptionGroup.push({
                                usage : params2[z].zucompositionList[o].mzPrescriptionTitle[0].usage,
                                days : params2[z].zucompositionList[o].mzPrescriptionTitle[0].days,
                                drippingSpeed : params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
                                drippingSpeedUnit : params2[z].zucompositionList[o].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
                                frequency : params2[z].zucompositionList[o].mzPrescriptionTitle[0].frequency,
                                startTime : params2[z].zucompositionList[o].mzPrescriptionTitle[0].startTime,
                                mzPrescriptionDetailSY : params2[z].zucompositionList[o].mzPrescriptionDetail
                        })
                      }
                    }
                }
            for (let i=0; i<params2.length; i++) {
                params2[i].isOk = false
            }
            _this.drugpatientDomain.getAuditMZPrescription(params,
                function (data) {
                    console.log(data.data)
            },
            function (err) {  
            console.log(err);
            });
        },
        handleClickTop(e,e1){
            if (e.label == '患者病历') {
                this.$router.push({
                    name:'interrogationIndex'
                })
            }
            if (e.label == '患者列表') {
                this.$router.push({
                    name:'intPatientList'
                })
            }
            if (e.label == '个人常用处方') {
                this.$router.push({
                    name:'preintPatientList'
                })
            }
            if (e.label == '处方管理') {
                this.$router.push({
                    name:'PremangePatientList'
                })
            }
        },
    }
}
</script>
<style scoped>
.imgBox {
    position: absolute;
    right: 500px;
    top: 200px;
}
    .table3Box {
    border: 1px solid #B9DCDD;
    border-radius: 6px;
    padding: 30px;
    margin-top: 10px;
    width: 95%;
    margin-left: 2.5%;
  }
    .inteBox {
        margin: 0;
        padding: 0;
        background-color: #F0F2F5;
    }
    .titleTop {
        padding: 0px 20px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .titleTopLeft {
        width: 85%;
        display: flex;
        align-items: center;
    }
    .titleTopLeftImg {
        width: 123px;
        height: 24px;
    }
    .titleTopLeftImg img {
        width: 100%;
        height: 100%;
    }
    .tabBox {
        padding-left: 12%;
        margin-top: 10px;
    }
    .el-tabs__item{
        height: 60px;
        color: white !important;
        text-align: center;
        padding-top: 10px;
        font-size: 25px !important;
        background-color: #00AFB5 !important;
        border: none !important;
    }
    .is-active{
    color: white !important;
    background-color: #00AFB5 !important;
    }
    ::v-deep .el-tabs__nav-wrap::after {
        background-color: #ffffff !important;
    }
    .titleTopRight {
        width: 10%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .titleTopRight div {
        margin-left: 10px;
    }
    .titleTopLine {
        width: 100%;
        height: 12px;
        background: #F0F2F5;
    }
    .titleTopCenter {
         width: 100%;
         /* height: 100; */
         display: flex;
    }
    .titleTopCenterLeft {
        width: 15%;
        background-color: #ffffff;
        /* padding-top: 30px; */
        min-height: 750px;
        overflow: auto;
    }
    .noflex-l-s{
        display: flex;
        justify-content: space-around;
    }
    .titleTopCenterLeftBox {
        font-size: 18px;
        color: #06ADD7;
        border: 1px solid #06AFD3;
        border-radius: 6px;
        width: 150px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        margin: 30px auto;
        cursor: pointer;
        /* margin-top: 0; */

    }
    .titleTopCenterLeftBox {
        font-size: 18px;
        color: #06ADD7;
        border: 1px solid #06AFD3;
        border-radius: 6px;
        width: 150px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        margin: 30px auto;
        cursor: pointer;
        /* margin-top: 0; */

    }
    .titleTopCenterLeftBoxno {
        font-size: 18px;
        color: #CCCCCC;
        border: 1px solid #CCCCCC;
        border-radius: 6px;
        width: 150px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        margin: 30px auto;
        cursor: pointer;
        /* margin-top: 0; */

    }
    .titleTopCenterCenter {
        width: 80%;
        border-radius: 6px;
        min-height: 750px;
        background-color: #ffffff;
    }
    .titleTopCenterCenterTop {
        background-color: #ffffff;
        padding: 0 40px;
        padding-top: 10px;
        padding-bottom: 10px;
        
    }
    .titleTopCenterCenterTopLine {
        border-bottom: 3px solid #F0F2F5;
    }
        .flex-l{
        display: flex;
        align-items: center;
    }
    .flex-l-s{
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .noflex-l-s{
        display: flex;
        justify-content: space-around;
    }
    .flex-l-b{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
     .Content1Title {
        padding:20px 40px;
        padding-left: 0;
        font-size: 18px;
        font-weight: bold;
        color: #333333;
        padding-bottom: 0;
        text-align: left;
    }
    .titleTopLeftTableC{
        color: #06ADD7;
        cursor: pointer;
    }
        .ageInput {
        width: 80%;
    }
        .typeChecked {
        font-size: 14px;
        color: #FFFFFF;
        padding: 0px 15px;
        text-align: center;
        background: #00AFB5;
        border-radius: 6px;
        cursor: pointer;
    }
    .notypeChecked {
        background: #F3F9F9;
        border: 1px solid #B9DCDD;
        padding: 0px 15px;
        border-radius: 6px;
        color: #00AFB5;
        font-size: 14px;
        text-align: center;
         cursor: pointer;
    }
    .titleTopright1{
    font-size: 16px;
    color: #06ADD7;
  }
  .greenColor {
    color: #06ADD7;
  }
  .titleSize16 {
    font-size: 16px;
  }
  .titleSize14 {
    font-size: 14px;
  }
  .titleSize22 {
    font-size: 22px;
  }
  .tagEdit {
    margin-left: 60%;
    width: 30%;
    height: 40px;
    border: 1px solid #00AFB5;
    color: #00AFB5;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
  }
  .patientimeBox {
    width: 24%;
    padding: 24px 30px;
    background: #FAFAFA;
    border: 1px solid #CFE5E6;
    border-radius: 8px;
    text-align: center;
    margin-right: 1%;
    cursor: pointer;
  }
  .patientimeBtom {
    text-align: left;
    margin-top: 15px;
  }
    .titleTopLeftTable {
        width: 50%;
    padding: 20px 35px;
    font-size: 16px;
    border-bottom: 2px solid #E1E3E6;
  }
  .titleTopLeftTableC{
    color: #06ADD7;
  }
  .notitleTopLeftTableC{
    color: #999999
  }
  .titleTopLeftTableTag {
    width: 10px;
    height: 10px;
    background: linear-gradient(90deg, #07ABDA, #01C4AF);
    border-radius: 50%;
    margin-right: 5px;
  }
  .titleTopLeftTableTag1 {
    width: 4px;
    height: 4px;
    background: #F82A2A;
    border-radius: 50%;
    margin-right: 5px;
  }
 .titleTopright1{
    font-size: 16px;
    color: #06ADD7;
  }
  .avatar-uploader-icon {
    font-size: 22px;
    color: #00AFB5;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #B9DCDD;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    background: #F3F9F9;
  }
   .avatar-uploader-icon:hover {
    font-size: 22px;
    color: #00AFB5;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #409EFF;
  }
  .avatar {
    width: 100px;
    height: 100px;
    display: block;
  }
  .PrintBox {
    width: 100%;
    background: #ffffff;
  }
  .PrintBoxTitle {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }
  .PrintBoxTitle1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .PrintBoxTitle2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .PrintBoxTitleLine {
    width: 100%;
    height: 1px;
    background: #000000;
    margin: 10px 0;
  }
  .PrintBoxTitleLineD {
    margin: 20px 0;
    border-bottom: 1px dashed #999999;
  }
</style>
